import React from "react"
import Layout from '../layout'
import SEO from '../components/seo'

export default () => (
    <Layout>
        <SEO title='404' />
        <h1>
            Oops! Page not found!
        </h1>
    </Layout>
)